








































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import tasqsListModule from '@/store/modules/tasqsListModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
  },
})
export default class Groups extends Vue {
   windowWidth = window.innerWidth;

   activeTableID: any = null;

    dataLoadingBackend = false;

   activeWorkspaceID: any = null;

   deleteGroupPopup = false;

    friendlyLayerName = '';

   deleteGroupParams: any = {};

   availableSearchOptions: any = [];

   stepNo: any = 0;

   newColumnName = '';

  headerText: any = ['Upload your assets', 'Organize your assets', 'Tag your assets'];

  nextButtonText: any = ['Continue to Organize', 'Continue to Organize', 'Continue to Match'];

  newGroupName = '';

  showCreateGroupModal =false;

  searchDataLoading = false;

  searchResultWells: any =[]

  selectedWellNames = [];

  availableWellNames = [];

  tempGroups = []

  searchTerms: any = [];

  searchTermsJson: any = [];

  nextStep() {
    this.stepNo += 1;
  }

  showModal = false;

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = []

   showWellMenu = false;

   foundSearchResult = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   showSearchPopup = false;

   newCloumnType = '';

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }

   async closeDialog() {
     this.showModal = false;
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }
}

